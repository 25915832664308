import { extendTheme } from '@chakra-ui/react';
var theme = extendTheme({
    fonts: {
        body: 'Source Sans Pro, sans-serif'
    },
    config: {
        initialColorMode: 'light',
        useSystemColorMode: false
    }
});
export default theme;
